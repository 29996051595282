import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  ContainedButton,
  Dialog,
  EmailInput,
  MultiSelect,
  OutlinedButton,
  SuccessToast,
  TextInput,
} from '@nordictrustee/nt-ui-library';
import { Group, User, UserFormFields } from 'modules/Managers/Managers.types';
import { confirmClose } from 'utils/confirmClose';
import { handleException } from 'utils/errorHandlingUtils';
import { checkIfErrors } from 'utils/reactHookFormUtils';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';
import * as api from '../../api';

interface Props {
  handleClose: () => void;
  user?: User;
  groups: Group[];
  getGroups: AxiosPromiseGeneric<Group[]>;
  getUsers: AxiosPromiseGeneric<User[]>;
}

const UserEditForm = ({
  handleClose,
  user,
  groups,
  getGroups,
  getUsers,
}: Props) => {
  const {
    control,
    errors,
    handleSubmit,
    formState,
    reset,
  } = useForm<UserFormFields>({
    mode: 'onBlur',
  });
  const { isDirty, isSubmitting } = formState;
  const { putUser } = api.usePutUser(user?.id);

  const fillFormFields = useCallback(() => {
    reset({
      ...user,
    });
  }, [user, reset]);

  useEffect(() => {
    fillFormFields();
  }, [fillFormFields]);

  const groupOptions = useMemo(
    () =>
      groups.map((group) => ({
        label: group.name,
        value: { id: group.id, name: group.name },
      })),
    [groups],
  );

  const onSubmit = handleSubmit(async (data) => {
    try {
      await putUser({ data });
      getGroups();
      getUsers();
      toast.success(<SuccessToast message="User has been saved" />);
      handleClose();
    } catch (e) {
      handleException(e);
    }
  });

  const handleConfirmClose = () => {
    confirmClose(isDirty, handleClose);
  };

  return (
    <Dialog
      open
      onClose={handleConfirmClose}
      fullWidth
      maxWidth="sm"
      title="Edit User"
      loadingContent={false}
      dialogActions={
        <>
          <ContainedButton
            onClick={onSubmit}
            data-testid="save-button"
            loading={isSubmitting}
          >
            save
          </ContainedButton>
          <OutlinedButton
            onClick={handleConfirmClose}
            data-testid="cancel-button"
          >
            cancel
          </OutlinedButton>
        </>
      }
    >
      <EmailInput
        control={control}
        errors={errors}
        data-testid="username"
        name="username"
        label="Username (Email)"
        autoFocus
        isRequired
      />
      <TextInput
        control={control}
        errors={errors}
        data-testid="firstName"
        name="firstName"
        label="First Name"
        isRequired
      />
      <TextInput
        control={control}
        errors={errors}
        data-testid="lastName"
        name="lastName"
        label="Last Name"
        isRequired
      />
      <MultiSelect
        control={control}
        name="groupList"
        options={groupOptions}
        label="Groups"
        data-testid="groups"
        menuPlacement="top"
        error={checkIfErrors('groupList', errors)}
        isClearable
        isSearchable
        isRequired
      />
    </Dialog>
  );
};

export default UserEditForm;
