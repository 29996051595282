import { useMsal } from '@azure/msal-react';
import { ContainedButton } from '@nordictrustee/nt-ui-library';
import { GetLoginRequest } from 'msalConfig';
import * as S from './Login.css';

const Login = () => {
  const { instance } = useMsal();

  const loginRequest = GetLoginRequest();

  const handleLogIn = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <S.Container>
      <p>Welcome to Nordic Trustee's Portal</p>
      <ContainedButton onClick={handleLogIn} data-testid="login-button">
        Login with Microsoft account
      </ContainedButton>
    </S.Container>
  );
};

export default Login;
