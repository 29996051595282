import React from 'react';
import { toast } from 'react-toastify';
import { ErrorToast } from '@nordictrustee/nt-ui-library';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { loginRequest, msalInstance, scopes } from 'msalConfig';

const axiosInstance = axios.create({
  baseURL: '/api',
});

const browserSessionIdHeader = 'X-NT-Browser-Session-Id';
const browserOriginHeader = 'X-NT-Browser-Request-Origin';
const browserOriginNameHeader = 'X-NT-Browser-Request-Origin-Name';

axiosInstance.interceptors.request.use(
  async (config) => {
    const faroSessionId = JSON.parse(
      sessionStorage.getItem('com.grafana.faro.session') ?? '',
    )?.sessionId;

    const browserRequestOrigin =
      window.location.origin + window.location.pathname;

    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.',
      );
    }
    return msalInstance
      .acquireTokenSilent({
        scopes: scopes,
        account: account,
      })
      .then((response) => {
        config.headers.set({
          ...config.headers,
          Authorization: `Bearer ${response.accessToken}`,
          [browserSessionIdHeader]: faroSessionId,
          [browserOriginHeader]: browserRequestOrigin,
          [browserOriginNameHeader]: 'Manager portal user management',
        });
        return config;
      })
      .catch((error) => {
        msalInstance.logoutRedirect();
        return error;
      });
  },
  (error) => {
    Promise.reject(error);
  },
);
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  async (error) => {
    if (axios.isCancel(error)) {
      console.warn(error);
      return { data: undefined };
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error?.response?.status === 401) {
      msalInstance.loginRedirect(loginRequest);
    }

    let serverMessage =
      error?.response?.data?.message || error?.response?.data?.Error?.Message;
    let serverDetails =
      error?.response?.data?.details || error?.response?.data?.Error?.Details;

    const message =
      serverMessage ||
      'Unknown error has occurred. Please contact Tillit team (Slack channel "nt-tillit").';

    // do not show error if response is cancelled (eg. on fast tabs switching)
    if (!axios.isCancel(error) && error?.response) {
      toast.error(<ErrorToast message={message} />, {
        autoClose: false,
      });
    }

    if (serverDetails) {
      console.error(`API response error details: ${serverDetails}`);
    }
    //Reject is necessary here for proper error handling of requests
    return Promise.reject(error);
  },
);

export const useAxios = makeUseAxios({
  axios: axiosInstance,
});
