import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultiSelect, TextInput } from '@nordictrustee/nt-ui-library';
import { Group, UserFormFields } from 'modules/Managers/Managers.types';
import { checkIfErrors } from 'utils/reactHookFormUtils';

interface Props {
  formContent?: UserFormFields;
  groups: Group[];
}

const UserFormInfoStep = ({ formContent, groups }: Props) => {
  const methods = useFormContext();
  const { errors, control, reset } = methods;

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);

  const groupOptions = useMemo(
    () =>
      groups.map((group) => ({
        label: group.name,
        value: { id: group.id, name: group.name },
      })),
    [groups],
  );

  return (
    <>
      <TextInput
        control={control}
        errors={errors}
        data-testid="firstName"
        name="firstName"
        label="First Name"
        autoFocus
        isRequired
      />
      <TextInput
        control={control}
        errors={errors}
        data-testid="lastName"
        name="lastName"
        label="Last Name"
        isRequired
      />
      <MultiSelect
        control={control}
        name="groupList"
        options={groupOptions}
        label="Groups"
        data-testid="groups"
        menuPlacement="top"
        error={checkIfErrors('groupList', errors)}
        isClearable
        isSearchable
        isRequired
      />
    </>
  );
};

export default UserFormInfoStep;
