import { useContext, useEffect, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { BoxEmpty, ChipList, Table } from '@nordictrustee/nt-ui-library';
import { defaultTheme } from 'themes';
import { User } from 'modules/Managers/Managers.types';
import { ManagersContext } from 'modules/Managers/ManagersContext';
import { firstColPadding } from 'utils/constants';
import { ActionsGeneric } from 'utils/types/ActionsGeneric';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';

interface Props {
  actions: ActionsGeneric<User>;
  showAllForManager: boolean;
  usersData: User[];
  isLoadingUsers: boolean;
  getUsers: AxiosPromiseGeneric<User[]>;
}

const UsersTable = ({
  actions,
  showAllForManager,
  usersData,
  isLoadingUsers,
  getUsers,
}: Props) => {
  const isBelowLargeScreenSize = useMediaQuery(
    defaultTheme.theme.breakpoints.down('lg'),
  );
  const { managerID, groupID } = useContext(ManagersContext);

  useEffect(() => {
    if (managerID > 0) {
      getUsers();
    }
  }, [getUsers, managerID]);

  const filterUsersOnGroupID = useMemo(() => {
    return usersData.filter((user) =>
      user.groupList.some((group) => group.id === groupID),
    );
  }, [groupID, usersData]);

  const filteredUsersData = showAllForManager
    ? usersData
    : filterUsersOnGroupID;

  const columns: Table.Column<User>[] = [
    {
      field: 'username',
      title: 'Username',
      width: '20%',
      render: (item) => (
        <Table.ColumnWithTooltip title={item.username}>
          {item.username}
        </Table.ColumnWithTooltip>
      ),
      cellStyle: { ...firstColPadding.cellStyle, width: '300px' },
      headerStyle: { ...firstColPadding.headerStyle },
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: '15%',
      render: (item) => (
        <Table.ColumnWithTooltip title={item.firstName}>
          {item.firstName}
        </Table.ColumnWithTooltip>
      ),
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: '15%',
      render: (item) => (
        <Table.ColumnWithTooltip title={item.lastName}>
          {item.lastName}
        </Table.ColumnWithTooltip>
      ),
    },
    {
      field: 'groups',
      title: 'Groups',
      width: 'calc(30% - 130px)',
      render: (item) => (
        <ChipList
          customMaxLabelLength={8}
          list={item.groupList.map((group) => group.name)}
          maxChips={isBelowLargeScreenSize ? 1 : 3}
        />
      ),
    },
  ];

  return managerID ? (
    <Table.Root
      columns={columns}
      data={filteredUsersData ?? []}
      isLoading={isLoadingUsers}
      disableDefaultSearchAndFilter
      options={{
        paging: false,
        search: false,
        toolbar: false,
        tableLayout: 'fixed',
        maxBodyHeight: '45.3vh',
        minBodyHeight: '45.3vh',
      }}
      actions={actions}
    />
  ) : (
    <BoxEmpty height="45.3vh" message="Select manager for the details" />
  );
};

export default UsersTable;
