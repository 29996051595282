import { USER_MANAGEMENT, USERS } from 'router/url';
import { useAxios } from 'utils/hooks/useAxios';

export const usePostUser = () => {
  const [, postUser] = useAxios(
    {
      url: `${USER_MANAGEMENT}${USERS}`,
      method: 'POST',
    },
    { manual: true },
  );
  return { postUser };
};

export const usePutUser = (userId?: number) => {
  const [, putUser] = useAxios(
    {
      url: `${USER_MANAGEMENT}${USERS}/${userId}`,
      method: 'PUT',
    },
    { manual: true },
  );
  return { putUser };
};

export const useDeleteUser = (deletedItemId: number) => {
  const [{ loading: isLoadingDeleteUser }, deleteUser] = useAxios(
    { url: `${USER_MANAGEMENT}${USERS}/${deletedItemId}`, method: 'DELETE' },
    { manual: true },
  );
  return { isLoadingDeleteUser, deleteUser };
};

export const useSendUserInvitation = (userId?: number) => {
  const [
    { loading: isLoadingSendUserInvitation },
    sendUserInvitation,
  ] = useAxios(
    {
      url: `${USER_MANAGEMENT}${USERS}/${userId}/invites`,
      method: 'POST',
    },
    {
      manual: true,
    },
  );
  return { isLoadingSendUserInvitation, sendUserInvitation };
};
