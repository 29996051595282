import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import {
  ContainedButton,
  Dialog,
  OutlinedButton,
  SuccessToast,
} from '@nordictrustee/nt-ui-library';
import { MANAGERS } from 'router/url';
import {
  ManagerAddFormFields,
  ManagersResult,
} from 'modules/Managers/Managers.types';
import { ManagersContext } from 'modules/Managers/ManagersContext';
import { confirmClose } from 'utils/confirmClose';
import { handleException } from 'utils/errorHandlingUtils';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';
import { PageableResponse } from 'utils/types/PageableResponse';
import * as managerDataApi from '../../../../api';
import * as api from '../../api';
import ManagerFormGroupStep from './components/ManagerFormGroupStep';
import ManagerFormManagerStep from './components/ManagerFormManagerStep';
import ManagerFormUsernameStep from './components/ManagerFormUsernameStep';
import ManagerFormUserStep from './components/ManagerFormUserStep';
import * as S from './ManagerStepper.css';

type Props = {
  open: boolean;
  onClose: () => void;
  getAssignedManagers: AxiosPromiseGeneric<PageableResponse<ManagersResult[]>>;
};

const stepsLabels = ['Add Manager', 'Add Group', 'Add Username', 'Add User'];

const ManagerStepper = ({ open, onClose, getAssignedManagers }: Props) => {
  const { push } = useHistory();
  const methods = useForm({ mode: 'onBlur' });
  const { watch, errors, handleSubmit, formState, setError } = methods;

  const [activeStep, setActiveStep] = useState(0);
  const [isStepperDirty, setStepperDirty] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [compiledForm, setCompiledForm] = useState<ManagerAddFormFields>({
    managerID: '',
    groupName: '',
    remark: '',
    username: '',
    firstName: '',
    lastName: '',
  });

  const {
    filterStringFromQuery,
    pageFromQuery,
    pageSizeFromQuery,
  } = useContext(ManagersContext);

  const form = watch();

  const { postManager } = api.usePostManager();
  const { validateUser } = managerDataApi.useValidateUser(form.username);

  const getStepContent = (step: number, formContent?: ManagerAddFormFields) => {
    switch (step) {
      case 0:
        return <ManagerFormManagerStep formContent={formContent} />;
      case 1:
        return <ManagerFormGroupStep formContent={formContent} />;
      case 2:
        return <ManagerFormUsernameStep formContent={formContent} />;
      case 3:
        return <ManagerFormUserStep formContent={formContent} />;
      default:
        return 'Unknown step';
    }
  };

  useEffect(() => {
    //Select on the first step is required, so it's enough to make stepperDitry
    if (activeStep === 0) {
      setStepperDirty(formState.isDirty);
    }
  }, [activeStep, formState.isDirty]);

  const isNewUser = async () => {
    setIsLoadingNext(true);
    const result = await validateUser();

    if (!result.data || result.data?.userExistInApp) {
      setError('username', {
        type: 'manual',
        message: 'A user with this username already exists',
      });
      setIsLoadingNext(false);
      return false;
    } else {
      const userInfo = result.data?.userInfo;
      setCompiledForm({
        ...compiledForm,
        username: form.username,
        firstName: userInfo ? userInfo.firstName : '',
        lastName: userInfo ? userInfo.lastName : '',
      });
      setIsLoadingNext(false);
      return true;
    }
  };

  const handleNext = handleSubmit(async () => {
    let canContinue = true;
    switch (activeStep) {
      case 0:
        setCompiledForm({
          ...compiledForm,
          managerID: form.managerID,
        });
        canContinue = true;
        break;
      case 1:
        setCompiledForm({
          ...compiledForm,
          groupName: form.groupName,
          remark: form.remark,
        });
        canContinue = true;
        break;
      case 2:
        setCompiledForm({
          ...compiledForm,
          username: form.username,
        });
        canContinue = await isNewUser();
        break;
      case 3:
        setCompiledForm({
          ...compiledForm,
          firstName: form.firstName,
          lastName: form.lastName,
        });
        canContinue = false;
        onSave({
          ...compiledForm,
          firstName: form.firstName,
          lastName: form.lastName,
        });
        break;
      default:
        return 'not a valid step';
    }
    if (canContinue) {
      setActiveStep((prevActiveStep) => ++prevActiveStep);
    }
  });

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => --prevActiveStep);
      setCompiledForm(form as ManagerAddFormFields);
    }
  };

  const handleConfirmClose = () => {
    confirmClose(isStepperDirty, onClose);
  };

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setCompiledForm({
        managerID: '',
        groupName: '',
        remark: '',
        username: '',
        firstName: '',
        lastName: '',
      });
    }
  }, [open]);

  const onSave = async (data: ManagerAddFormFields) => {
    try {
      const formData = {
        group: {
          name: data.groupName,
          managerID: data.managerID,
          remark: data.remark,
        },
        user: {
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      };
      const response = await postManager({ data: formData });
      if (response?.data?.groupID) {
        push(
          `${MANAGERS}?managerId=${data.managerID}&groupId=${response.data.groupID}&page=${pageFromQuery}&pageSize=${pageSizeFromQuery}&query=${filterStringFromQuery}`,
        );
      }
      getAssignedManagers();
      toast.success(<SuccessToast message="Manager has been saved" />);
      onClose();
    } catch (e) {
      handleException(e);
    }
  };

  const continueDisabled = !!Object.values(errors).length;

  return (
    <Dialog
      open={open}
      onClose={handleConfirmClose}
      fullWidth
      maxWidth="sm"
      title="Add Manager"
      dialogActions={
        <>
          <ContainedButton
            onClick={handleNext}
            data-testid="continue-button"
            disabled={continueDisabled}
            loading={isLoadingNext}
          >
            {activeStep === stepsLabels.length - 1 ? 'save' : 'continue'}
          </ContainedButton>
          <OutlinedButton
            onClick={handleConfirmClose}
            data-testid="cancel-button"
          >
            cancel
          </OutlinedButton>
          <ContainedButton
            onClick={handleBack}
            data-testid="back-button"
            disabled={activeStep === 0}
          >
            back
          </ContainedButton>
        </>
      }
    >
      <FormProvider {...methods}>
        <S.Container>
          <Stepper activeStep={activeStep}>
            {stepsLabels.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box mt={2}>{getStepContent(activeStep, compiledForm)}</Box>
        </S.Container>
      </FormProvider>
    </Dialog>
  );
};

export default ManagerStepper;
