import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import {
  ContainedButton,
  Container,
  Table,
} from '@nordictrustee/nt-ui-library';
import { MANAGERS } from 'router/url';
import { ManagersResult } from 'modules/Managers/Managers.types';
import { ManagersContext } from 'modules/Managers/ManagersContext';
import { firstColPadding } from 'utils/constants';
import { usePaginatedTable, useToggle } from 'utils/hooks';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { ToolbarComponentType } from 'utils/types/ToolbarComponentType';
import ManagerStepper from './components/ManagerStepper/ManagerStepper';
import * as api from './api';
import * as S from './ManagersTable.css';

const columns: Table.Column<ManagersResult>[] = [
  {
    field: 'manager',
    title: 'Manager',
    render: (item) => (
      <Table.ColumnWithTooltip title={item.name}>
        {item.name}
      </Table.ColumnWithTooltip>
    ),
    cellStyle: {
      ...firstColPadding.cellStyle,
      width: '300px',
      maxWidth: '300px',
    },
    headerStyle: { ...firstColPadding.headerStyle },
  },
  {
    field: 'country',
    title: 'Country',
    render: (item) => (
      <Table.ColumnWithTooltip title={item.country}>
        {item.country}
      </Table.ColumnWithTooltip>
    ),
  },
];

const ManagersTable = () => {
  const { push } = useHistory();
  const {
    managerID,
    groupID,
    filterStringFromQuery,
    pageFromQuery,
    pageSizeFromQuery,
  } = useContext(ManagersContext);

  const [search, setSearch] = useState(filterStringFromQuery);

  const [isFormOpen, handleOpenFormModal, handleCloseFormModal] = useToggle();

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginatedTable(pageSizeFromQuery, pageFromQuery);

  const handleSearch = useCallback(
    (searchText: string) => {
      setSearch(searchText);
      handleChangePage(0);
    },
    [handleChangePage],
  );

  const {
    assignedManagersData,
    isLoadingAssignedManagers,
    getAssignedManagers,
  } = api.useGetAssignedManagers(page, rowsPerPage, search);

  const params = useMemo(
    () => ({
      groupId: groupID.toString(),
      page: page.toString(),
      pageSize: rowsPerPage.toString(),
      query: search,
    }),
    [groupID, page, rowsPerPage, search],
  );
  const searchQuery = useMemo(() => new URLSearchParams(params), [params]);

  useEffect(() => {
    push(`${MANAGERS}?managerId=${managerID}&${searchQuery}`);
  }, [groupID, managerID, page, push, rowsPerPage, search, searchQuery]);

  useEffect(() => {
    getAssignedManagers();
  }, [getAssignedManagers]);

  const ToolbarComponent: ToolbarComponentType<ManagersResult> = useMemo(
    () => (props) => (
      <S.Header>
        <Box display="flex" alignItems="center" paddingLeft={0}>
          <Table.Toolbar
            {...props}
            classes={{
              searchField: 'material-table-search-field',
            }}
          />
        </Box>
        <ContainedButton
          startIcon={<AddIcon />}
          onClick={handleOpenFormModal}
          data-testid="container-storybook-icon"
        >
          Add Manager
        </ContainedButton>
      </S.Header>
    ),
    [handleOpenFormModal],
  );

  const tableComponents: Table.Components = useMemo(
    () => ({
      Toolbar: (props) => <ToolbarComponent {...props} />,
    }),
    [ToolbarComponent],
  );

  return (
    <>
      <Container isHeader={false} noBottomBorder={true}>
        <Table.Root
          components={tableComponents}
          columns={columns}
          data={assignedManagersData?.results ?? []}
          isLoading={isLoadingAssignedManagers}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          disableDefaultSearchAndFilter
          options={{
            searchAutoFocus: true,
            searchText: search,
            actionsColumnIndex: -1,
            searchFieldAlignment: 'left',
            pageSize: rowsPerPage,
            pageSizeOptions: [10, 25, 50, 100],
            debounceInterval: 300,
            maxBodyHeight: '70vh',
            minBodyHeight: '70vh',
            paging: true,
            searchFieldStyle: { width: '260px' },
            rowStyle: (rowData: ManagersResult) =>
              selectedRowStyle(rowData.id, managerID),
          }}
          onSearchChange={handleSearch}
          localization={{
            toolbar: {
              searchPlaceholder: 'Search for manager',
            },
          }}
          page={page}
          totalCount={assignedManagersData?.count}
          onRowClick={(_, item) => {
            push(`${MANAGERS}?managerId=${item?.id}&${searchQuery}`);
          }}
        />
      </Container>
      {isFormOpen && (
        <ManagerStepper
          open={isFormOpen}
          onClose={handleCloseFormModal}
          getAssignedManagers={getAssignedManagers}
        />
      )}
    </>
  );
};

export default ManagersTable;
