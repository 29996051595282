import { MANAGERS, USER_MANAGEMENT, USERS } from 'router/url';
import {
  Group,
  User,
  ValidatedUserInfo,
} from 'modules/Managers/Managers.types';
import { useAxios } from 'utils/hooks/useAxios';

export const useGetUsers = (managerID: number) => {
  const [
    { data: usersData = [], loading: isLoadingUsers },
    getUsers,
  ] = useAxios<User[]>(`${USER_MANAGEMENT}${MANAGERS}/${managerID}/users`, {
    manual: true,
  });
  return { usersData, isLoadingUsers, getUsers };
};

export const useGetGroups = (managerId: number) => {
  const [
    { data: groupsData = [], loading: isLoadingGroups },
    getGroups,
  ] = useAxios<Group[]>(`${USER_MANAGEMENT}${MANAGERS}/${managerId}/groups`, {
    manual: true,
  });
  return { groupsData, isLoadingGroups, getGroups };
};

export const useValidateUser = (username: string) => {
  const [
    { data: validatedUserData, loading: isLoadingUser },
    validateUser,
  ] = useAxios<ValidatedUserInfo>(
    `${USER_MANAGEMENT}${USERS}/validate?username=${username}`,
    {
      manual: true,
    },
  );
  return { validatedUserData, isLoadingUser, validateUser };
};
