import React, { createContext, ReactNode, useMemo } from 'react';
import { useQuery } from 'utils/hooks';

export type Props = {
  children: ReactNode;
};

export type ProviderValue = {
  managerID: number;
  groupID: number;
  filterStringFromQuery: string;
  pageFromQuery: number;
  pageSizeFromQuery: number;
};

export const ManagersContext = createContext<ProviderValue>({
  managerID: 0,
  groupID: 0,
  filterStringFromQuery: '',
  pageFromQuery: 0,
  pageSizeFromQuery: 25,
});

export const ManagersProvider = ({ children }: Props) => {
  const query = useQuery();
  const managerID = useMemo(() => Number(query.get('managerId')) || 0, [query]);
  const groupID = useMemo(() => Number(query.get('groupId')) || 0, [query]);
  const filterStringFromQuery = useMemo(() => query.get('query') || '', [
    query,
  ]);
  const pageFromQuery = useMemo(() => Number(query.get('page')) || 0, [query]);
  const pageSizeFromQuery = useMemo(() => Number(query.get('pageSize')) || 25, [
    query,
  ]);

  const value = useMemo(
    () => ({
      managerID,
      groupID,
      filterStringFromQuery,
      pageFromQuery,
      pageSizeFromQuery,
    }),
    [
      filterStringFromQuery,
      groupID,
      managerID,
      pageFromQuery,
      pageSizeFromQuery,
    ],
  );

  return (
    <ManagersContext.Provider value={value}>
      {children}
    </ManagersContext.Provider>
  );
};
