import { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
  createReactRouterV5Options,
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
  ReactRouterHistory,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { ThemeProvider, ToastProvider } from '@nordictrustee/nt-ui-library';
import { createBrowserHistory } from 'history';
import routes from 'router/routes';
import { EnvironmentName } from 'utils/types/EnvironmentName';
import './index.css';

type Props = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: Props) => {
  useEffect(() => {
    const { ENVIRONMENT_NAME, GRAFANA_URL } = window._env_;

    if (ENVIRONMENT_NAME && ENVIRONMENT_NAME !== EnvironmentName.prod) {
      const favicon = document.getElementById('favicon');
      // favicon-test.ico | favicon-uat.ico | favicon-hotfix.ico
      favicon?.setAttribute('href', `/favicon-${ENVIRONMENT_NAME}.ico?v=1`);
    }

    if (GRAFANA_URL) {
      initializeFaro({
        url: GRAFANA_URL,
        app: {
          name: 'Manager-portal-user-management-frontend-UI',
          version: process.env.REACT_APP_VERSION,
          environment: ENVIRONMENT_NAME,
        },

        instrumentations: [
          // Mandatory, omits default instrumentations otherwise.
          ...getWebInstrumentations({
            captureConsole: ENVIRONMENT_NAME === 'prod',
          }),
          // Tracing package to get end-to-end visibility for HTTP requests.
          new TracingInstrumentation(),

          // React integration for React applications.
          new ReactIntegration({
            router: createReactRouterV5Options({
              history: (createBrowserHistory() as unknown) as ReactRouterHistory, // the history object used by react-router
              Route, // Route component imported from react-router package
            }),
          }),
        ],
      });
    }
  }, []);

  return (
    <MsalProvider instance={pca}>
      <Router>
        <ToastProvider />
        <ThemeProvider>{routes}</ThemeProvider>
      </Router>
    </MsalProvider>
  );
};

export default App;
