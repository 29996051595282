import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { EmailInput } from '@nordictrustee/nt-ui-library';
import { UserFormFields } from 'modules/Managers/Managers.types';

type Props = {
  formContent?: UserFormFields;
};

export const UserFormUsernameStep = ({ formContent }: Props) => {
  const methods = useFormContext();
  const { reset, control, errors } = methods;

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);

  return (
    <EmailInput
      control={control}
      errors={errors}
      data-testid="username"
      name="username"
      label="Username (Email)"
      autoFocus
      isRequired
    />
  );
};

export default UserFormUsernameStep;
