import { useContext } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { ManagersContext } from 'modules/Managers/ManagersContext';
import GroupList from './components/GroupList';
import ManagersTable from './components/ManagersTable';
import UserList from './components/UserList';
import * as api from './api';

const ManagerData = () => {
  const { managerID } = useContext(ManagersContext);
  const { usersData, isLoadingUsers, getUsers } = api.useGetUsers(managerID);
  const { groupsData, isLoadingGroups, getGroups } = api.useGetGroups(
    managerID,
  );

  return (
    <>
      <Container maxWidth="xl">
        <Box mb={3}>
          <Typography variant="h2">Managers</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <ManagersTable />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GroupList
                  usersData={usersData}
                  getUsers={getUsers}
                  groupsData={groupsData}
                  isLoadingGroups={isLoadingGroups}
                  getGroups={getGroups}
                />
              </Grid>
              <Grid item xs={12}>
                <UserList
                  usersData={usersData}
                  isLoadingUsers={isLoadingUsers}
                  getUsers={getUsers}
                  getGroups={getGroups}
                  groups={groupsData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ManagerData;
