import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput } from '@nordictrustee/nt-ui-library';
import { ManagerAddFormFields } from 'modules/Managers/Managers.types';

interface Props {
  formContent?: ManagerAddFormFields;
}

const ManagerFormUserStep = ({ formContent }: Props) => {
  const methods = useFormContext();
  const { errors, control, reset } = methods;

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);

  return (
    <>
      <TextInput
        control={control}
        errors={errors}
        data-testid="firstName"
        name="firstName"
        label="First Name"
        autoFocus
        isRequired
      />
      <TextInput
        control={control}
        errors={errors}
        data-testid="lastName"
        name="lastName"
        label="Last Name"
        isRequired
      />
    </>
  );
};

export default ManagerFormUserStep;
