import { MANAGERS, USER_MANAGEMENT } from 'router/url';
import { ManagersResult } from 'modules/Managers/Managers.types';
import { useAxios } from 'utils/hooks/useAxios';
import { PageableResponse } from 'utils/types/PageableResponse';

export const useGetAssignedManagers = (
  page: number,
  rowsPerPage: number,
  filterString?: string,
) => {
  const [
    { data: assignedManagersData, loading: isLoadingAssignedManagers },
    getAssignedManagers,
  ] = useAxios<PageableResponse<ManagersResult[]>>(
    `${USER_MANAGEMENT}/assigned-managers?page=${page}&pageSize=${rowsPerPage}&filterString=${filterString}`,
    { manual: true },
  );
  return {
    assignedManagersData,
    isLoadingAssignedManagers,
    getAssignedManagers,
  };
};

export const useGetManagers = (filterString?: string) => {
  const [
    { data: managersData, loading: isLoadingManagers },
    getManagers,
  ] = useAxios<PageableResponse<ManagersResult[]>>(
    `${USER_MANAGEMENT}/managers?page=0&pageSize=15&filterString=${filterString}`,
    { manual: true },
  );
  return {
    managersData,
    isLoadingManagers,
    getManagers,
  };
};

export const usePostManager = () => {
  const [, postManager] = useAxios(
    {
      url: `${USER_MANAGEMENT}${MANAGERS}`,
      method: 'POST',
    },
    { manual: true },
  );
  return { postManager };
};
