import { NavLink } from 'react-router-dom';
import { Button } from '@nordictrustee/nt-ui-library';
import styled from 'styled-components';
import { defaultTheme } from 'themes';
import Toolbar from '../Toolbar';

export const LogoLink = styled(NavLink)`
  padding: 2rem 1rem 0.5rem 1rem;
  &.active {
    background-color: ${defaultTheme.theme.palette.grey[800]};
    border-bottom: 4px solid ${defaultTheme.theme.palette.common.white};
  }
`;

export const Header = styled.header`
  background-color: ${defaultTheme.theme.palette.common.black};
  color: ${defaultTheme.theme.palette.common.white};
  margin-top: -0.3rem;
`;

export const LogoParagraph = styled.p`
  margin: 1rem 1rem 1rem 0.5rem;
`;

export const MainMenuBox = styled.div`
  cursor: pointer;
`;

export const MainMenuLink = styled(NavLink)`
  text-transform: uppercase;
  padding: 1.5rem 1rem 20px 1rem;
  color: ${defaultTheme.theme.palette.common.white};
  font-weight: 500;
  line-height: 20px;
  font-size: 0.8125rem;
  &.active {
    background-color: ${defaultTheme.theme.palette.grey[800]};
    border-bottom: 4px solid ${defaultTheme.theme.palette.common.white};
  }
`;

export const DropdownMenuLink = styled(NavLink)`
  text-transform: uppercase;
  padding: 1rem;
  display: flex;
  font-weight: 500;
  align-items: center;
  color: ${defaultTheme.theme.palette.primary.main};
  svg {
    display: none;
  }
  &.active {
    padding-left: 0.5rem;
    font-weight: 900;
    svg {
      display: inline-block;
    }
  }
`;

export const RightToolbar = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  & > button {
    color: white;
  }
  @media (max-width: 1500px) {
    & > button {
      padding: 0;
      margin-right: 1rem;
    }
  }
`;

export const HeaderToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

export const LeftToolbar = styled(RightToolbar)`
  @media (min-width: 1501px) {
    & > button {
      display: none;
    }
  }
`;

export const MenuItem = styled(Button)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 3rem;
    justify-content: flex-start;
    font-size: 0.8125rem;
  }
`;
