import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Layout from 'components/Layout';
import Login from 'modules/Login';
import { ManagersProvider } from 'modules/Managers/ManagersContext';
import ManagerData from 'modules/Managers/screens/ManagerData';
import * as URL from './url';

const ManagersRoute = ManagerData;

export default (
  <Layout>
    <UnauthenticatedTemplate>
      <Route path={URL.URL_LOGIN}>
        <Login />
      </Route>
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <Switch>
        <Route exact path={URL.MANAGERS}>
          <ManagersProvider>
            <ManagersRoute />
          </ManagersProvider>
        </Route>
        <Redirect to={URL.MANAGERS} />
      </Switch>
    </AuthenticatedTemplate>
  </Layout>
);
