// General
export const URL_ROOT = '/';
export const URL_LOGIN = '/login';
export const USER_MANAGEMENT = '/user-management';

// Managers
export const MANAGERS = '/managers';
export const MANAGERS_SEARCH = '/managers/search';

// Groups
export const GROUPS = '/groups';

// Users
export const USERS = '/users';
