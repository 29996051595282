import { defaultTheme } from 'themes';

export const adjustedDateDisplayFormat: string = 'YYYY-MM-DD';
export const dateDisplayFormat: string = 'DD.MM.YYYY';
export const monthYearDisplayFormat: string = 'MM.YYYY';
export const currencyDecimalSeparator: string = ',';

export const firstColPadding = {
  cellStyle: {
    paddingLeft: '1rem',
  },
  headerStyle: {
    paddingLeft: '1rem',
  },
};

export const firstColZeroPadding = {
  cellStyle: {
    paddingLeft: '0',
  },
  headerStyle: {
    paddingLeft: '0',
  },
};

export const linkFirstColPadding = {
  cellStyle: {
    padding: '0 0 0 0.5rem',
  },
  headerStyle: {
    paddingLeft: '1rem',
  },
};

export const linkColPadding = {
  cellStyle: {
    padding: '0',
  },
};

export const centerText: React.CSSProperties = {
  textAlign: 'center',
};

export const rightText: React.CSSProperties = {
  textAlign: 'right',
};

export const columnRightText = {
  cellStyle: { ...rightText },
  headerStyle: { ...rightText },
};

export const columnRightAndHeaderCenterText = {
  cellStyle: { ...rightText },
  headerStyle: { ...centerText },
};

export const columnRightTextWithPadding = {
  cellStyle: { ...rightText, paddingRight: '1rem' },
  headerStyle: { ...rightText, paddingRight: '1rem' },
};

export const wrapCell: React.CSSProperties = {
  whiteSpace: 'normal',
};

export const firstColPaddingWithCenterText = {
  cellStyle: {
    paddingLeft: '1rem',
    ...centerText,
  },
  headerStyle: {
    paddingLeft: '1rem',
    ...centerText,
  },
};

export const centerColumnText = {
  cellStyle: centerText,
  headerStyle: centerText,
};

export const centerFilterColumnText = {
  headerStyle: centerText,
  cellStyle: { ...centerText, paddingRight: '2rem' },
};

export const columnWithRightBorder = {
  cellStyle: {
    borderRight: `1px solid ${defaultTheme.theme.palette.divider}`,
  },
  headerStyle: {
    borderRight: `1px solid ${defaultTheme.theme.palette.divider}`,
  },
};

// reference - https://reactrouter.com/web/api/history
export const historyActions = {
  PUSH: 'PUSH',
  POP: 'POP',
  REPLACE: 'REPLACE',
};

export const unicodeCharacters = {
  NON_BREAKING_SPACE: '\u00A0',
  THREE_DOTS: '\u2026',
};

export const tableRowHeight = 48; // 48px === 3rem

export const documentContainerWidth = '600px';

export const maxNumberInputValue = '9999999999999.99999999';
