import { GROUPS, USER_MANAGEMENT } from 'router/url';
import { useAxios } from 'utils/hooks/useAxios';

export const usePostGroup = () => {
  const [, postGroup] = useAxios(
    {
      url: `${USER_MANAGEMENT}${GROUPS}`,
      method: 'POST',
    },
    { manual: true },
  );
  return { postGroup };
};

export const usePutGroup = (groupId?: number) => {
  const [, putGroup] = useAxios(
    {
      url: `${USER_MANAGEMENT}${GROUPS}/${groupId}`,
      method: 'PUT',
    },
    { manual: true },
  );
  return { putGroup };
};

export const useDeleteGroup = (deletedItemId: number) => {
  const [{ loading: isLoadingDeleteGroup }, deleteGroup] = useAxios(
    { url: `${USER_MANAGEMENT}${GROUPS}/${deletedItemId}`, method: 'DELETE' },
    { manual: true },
  );
  return { isLoadingDeleteGroup, deleteGroup };
};
