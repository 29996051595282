import { Toolbar as MaterialToolbar } from '@mui/material';
import styled from 'styled-components';
import { defaultTheme } from 'themes';

export const Toolbar = styled(MaterialToolbar)`
  max-height: '64px';
  display: flex;
  justify-content: space-between;
  max-width: ${`${defaultTheme.theme.breakpoints.values.xl}px`};
  margin: 0 auto;
  min-height: 4rem;
  box-sizing: border-box;
  &.MuiToolbar-gutters {
    padding: 0 45px;
  }
`;
