import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ContainedButton,
  Dialog,
  MultilineInput,
  MultiSelect,
  OutlinedButton,
  SuccessToast,
  TextInput,
} from '@nordictrustee/nt-ui-library';
import { MANAGERS } from 'router/url';
import { Group, GroupFormFields, User } from 'modules/Managers/Managers.types';
import { ManagersContext } from 'modules/Managers/ManagersContext';
import { confirmClose } from 'utils/confirmClose';
import { handleException } from 'utils/errorHandlingUtils';
import { helperText } from 'utils/reactHookFormUtils';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';
import * as api from '../../api';
import { canRemoveAllUsers, isUserRemovable } from '../../utils';

interface Props {
  handleClose: () => void;
  editMode?: boolean;
  group?: Group;
  getGroups: AxiosPromiseGeneric<Group[]>;
  usersData: User[];
  getUsers: AxiosPromiseGeneric<User[]>;
}

const GroupForm = ({
  handleClose,
  editMode,
  group,
  getGroups,
  usersData,
  getUsers,
}: Props) => {
  const {
    control,
    errors,
    handleSubmit,
    formState,
    reset,
  } = useForm<GroupFormFields>({
    mode: 'onBlur',
  });
  const { isDirty, isSubmitting } = formState;
  const { push } = useHistory();
  const remarkValue = useWatch({
    control,
    name: 'remark',
    defaultValue: '',
  });
  const {
    managerID,
    pageFromQuery,
    pageSizeFromQuery,
    filterStringFromQuery,
  } = useContext(ManagersContext);

  const [selectedUserValues, setSelectedUserValues] = useState(
    group
      ? group.users.map((u) => ({
          label: u.fullName,
          value: u.id,
          isFixed: !isUserRemovable(usersData, u.id, group.id),
        }))
      : [],
  );
  const { postGroup } = api.usePostGroup();
  const { putGroup } = api.usePutGroup(group?.id);

  const fillFormFields = useCallback(() => {
    if (editMode) {
      reset({ ...group, userIDs: group?.users.map((u) => u.id) });
    }
  }, [group, editMode, reset]);

  useEffect(() => {
    fillFormFields();
  }, [fillFormFields]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formData = {
        ...data,
        managerID,
        userIDs: data.userIDs ?? [],
      };
      if (editMode) {
        await putGroup({ data: formData });
      } else {
        const response = await postGroup({ data: formData });
        if (response?.data?.id) {
          push(
            `${MANAGERS}?managerId=${managerID}&groupId=${response.data.id}&page=${pageFromQuery}&pageSize=${pageSizeFromQuery}&query=${filterStringFromQuery}`,
          );
        }
      }
      getGroups();
      getUsers();
      toast.success(<SuccessToast message="Group has been saved" />);
      handleClose();
    } catch (e) {
      handleException(e);
    }
  });

  const handleConfirmClose = () => {
    confirmClose(isDirty, handleClose);
  };

  const assignableSelectOptions = useMemo(
    () =>
      usersData.map((user) => ({
        label: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
        value: user.id,
        isFixed: !isUserRemovable(usersData, user.id, group?.id),
      })),
    [group?.id, usersData],
  );

  const removeItems = (userIDs: number[]) => {
    selectedUserValues.forEach((element) => {
      if (!userIDs.some((id) => id === element.value)) {
        setSelectedUserValues(
          selectedUserValues.filter((v) => v.value !== element.value),
        );
      }
    });
  };

  const addNewItems = (userIDs: number[]) => {
    userIDs.forEach((id) => {
      if (!selectedUserValues.some((user) => user.value === id)) {
        const newItem = assignableSelectOptions?.find((u) => u.value === id);
        setSelectedUserValues([...selectedUserValues, newItem!]);
      }
    });
  };

  const handleChange = (userIDs: number[]) => {
    if (userIDs == null || userIDs.length === 0) {
      const nonRemovableValues = selectedUserValues.filter(
        (users) => users.isFixed,
      );
      setSelectedUserValues(nonRemovableValues);
    } else {
      removeItems(userIDs);
      addNewItems(userIDs);
    }
  };

  const userOptions = useMemo(
    () => [...(selectedUserValues || []), ...(assignableSelectOptions || [])],
    [assignableSelectOptions, selectedUserValues],
  );

  const dialogTitle = `${editMode ? 'Edit' : 'Add'} Group`;

  return (
    <Dialog
      open
      onClose={handleConfirmClose}
      fullWidth
      maxWidth="sm"
      title={dialogTitle}
      loadingContent={false}
      dialogActions={
        <>
          <ContainedButton
            onClick={onSubmit}
            data-testid="save-button"
            loading={isSubmitting}
          >
            save
          </ContainedButton>
          <OutlinedButton
            onClick={handleConfirmClose}
            data-testid="cancel-button"
          >
            cancel
          </OutlinedButton>
        </>
      }
    >
      <TextInput
        control={control}
        errors={errors}
        data-testid="name"
        name="name"
        label="Name"
        autoFocus
        isRequired
      />
      <MultilineInput
        control={control}
        name="remark"
        data-testid="remark"
        rows={6}
        label="Remark"
        value={remarkValue}
        errorMessage={helperText('remark', errors)}
      />
      <MultiSelect
        control={control}
        name="userIDs"
        options={userOptions}
        label="Users"
        data-testid="users"
        menuPlacement="top"
        onChange={handleChange}
        helperText="Search for user"
        maxMenuHeight={220}
        isClearable={canRemoveAllUsers(selectedUserValues)}
        isSearchable
      />
    </Dialog>
  );
};

export default GroupForm;
