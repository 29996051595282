import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { FormControlLabel, Switch } from '@mui/material';
import {
  ConfirmDialog,
  ContainedButton,
  Container,
  SuccessToast,
} from '@nordictrustee/nt-ui-library';
import { MANAGERS } from 'router/url';
import { Group, User } from 'modules/Managers/Managers.types';
import { ManagersContext } from 'modules/Managers/ManagersContext';
import { handleException } from 'utils/errorHandlingUtils';
import { useToggle } from 'utils/hooks';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';
import UserEditForm from './comopnents/UserEditForm';
import UsersTable from './comopnents/UsersTable';
import UserStepper from './comopnents/UserStepper';
import * as api from './api';

interface Props {
  usersData: User[];
  isLoadingUsers: boolean;
  getUsers: AxiosPromiseGeneric<User[]>;
  getGroups: AxiosPromiseGeneric<Group[]>;
  groups: Group[];
}

const UserList = ({
  usersData,
  isLoadingUsers,
  getUsers,
  getGroups,
  groups,
}: Props) => {
  const { push } = useHistory();
  const [showAllForManager, setShowAllForManager] = useState(true);
  const [editedItem, setEditedItem] = useState<User>();
  const [deletedId, setDeletedId] = useState<number>();
  const [sendUserId, setSendUserId] = useState<number>();
  const [sendUsername, setSendUsername] = useState<string>();
  const {
    managerID,
    groupID,
    filterStringFromQuery,
    pageFromQuery,
    pageSizeFromQuery,
  } = useContext(ManagersContext);

  const [
    isUserAddFormOpen,
    handleOpenUserAddForm,
    handleCloseUserAddForm,
  ] = useToggle();
  const [
    isUserEditFormOpen,
    handleOpenUserEditForm,
    handleCloseUserEditForm,
  ] = useToggle();

  useEffect(() => {
    if (groupID) {
      setShowAllForManager(false);
    } else {
      setShowAllForManager(true);
    }
  }, [groupID]);

  useEffect(() => {
    if (managerID) {
      setShowAllForManager(true);
    }
  }, [managerID]);

  const [
    isDeleteDialogOpen,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
  ] = useToggle();

  const [
    isSendUserInvitationDialogOpen,
    handleOpenSendUserInvitationDialog,
    handleCloseSendUserInvitationDialog,
  ] = useToggle();

  const { isLoadingDeleteUser, deleteUser } = api.useDeleteUser(deletedId!);

  const {
    isLoadingSendUserInvitation,
    sendUserInvitation,
  } = api.useSendUserInvitation(sendUserId);

  const handleEditOpen = (data: User) => {
    setEditedItem(data);
    handleOpenUserEditForm();
  };

  const handleAddOpen = () => {
    handleOpenUserAddForm();
  };

  const handleDeleteConfirmOpen = (id: number) => {
    setDeletedId(id);
    handleOpenDeleteDialog();
  };

  const handleDelete = async () => {
    try {
      await deleteUser();
      getGroups();
      getUsers();
      handleCloseDeleteDialog();
      toast.success(<SuccessToast message="User has been deleted" />);
    } catch (e) {
      handleException(e);
    }
  };

  const handleSwitch = () => {
    setShowAllForManager(!showAllForManager);
    push(
      `${MANAGERS}?managerId=${managerID}&groupId=&page=${pageFromQuery}&pageSize=${pageSizeFromQuery}&query=${filterStringFromQuery}`,
    );
  };

  const handleSendUserInvitationConfirmOpen = (
    id: number,
    username: string,
  ) => {
    setSendUserId(id);
    setSendUsername(username);
    handleOpenSendUserInvitationDialog();
  };

  const handleSendInvitation = async () => {
    try {
      await sendUserInvitation();
      toast.success(
        <SuccessToast
          message={`Invitation has been sent to ${sendUsername}`}
        />,
      );
    } catch (e) {
      handleException(e);
    }
  };

  return (
    <>
      <Container
        title="Users"
        headerControls={
          <>
            <FormControlLabel
              control={
                <>
                  <Switch checked={showAllForManager} color="primary" />
                </>
              }
              onClick={handleSwitch}
              label="Show all users for the manager"
            />
            <ContainedButton
              startIcon={<AddIcon />}
              onClick={handleAddOpen}
              data-testid="add-user-button"
            >
              Add User
            </ContainedButton>
          </>
        }
      >
        <UsersTable
          actions={[
            ({ id, username }) => ({
              icon: () => <SendIcon color="action" />,
              tooltip: 'Send Invitation',
              onClick: () => handleSendUserInvitationConfirmOpen(id, username),
            }),
            (data) => ({
              icon: () => <EditIcon color="action" />,
              tooltip: 'Edit',
              onClick: () => handleEditOpen(data),
            }),
            ({ id }) => ({
              icon: () => <DeleteIcon color="action" />,
              tooltip: 'Delete',
              onClick: () => handleDeleteConfirmOpen(id),
            }),
          ]}
          showAllForManager={showAllForManager}
          usersData={usersData}
          isLoadingUsers={isLoadingUsers}
          getUsers={getUsers}
        />
      </Container>
      {isSendUserInvitationDialogOpen && (
        <ConfirmDialog
          title="Confirm invitation"
          open={isSendUserInvitationDialogOpen}
          message={`Do you want to send invitation to ${sendUsername}?`}
          submitText="Send"
          onCancel={handleCloseSendUserInvitationDialog}
          onSubmit={handleSendInvitation}
          isSubmitDisabled={isLoadingSendUserInvitation}
        />
      )}
      {isUserAddFormOpen && (
        <UserStepper
          open={isUserAddFormOpen}
          onClose={handleCloseUserAddForm}
          groups={groups}
          getGroups={getGroups}
          getUsers={getUsers}
        />
      )}
      {isUserEditFormOpen && (
        <UserEditForm
          handleClose={handleCloseUserEditForm}
          user={editedItem}
          groups={groups}
          getGroups={getGroups}
          getUsers={getUsers}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmDialog
          open={isDeleteDialogOpen}
          message="Do you want to delete this user?"
          submitText="Delete"
          onCancel={handleCloseDeleteDialog}
          onSubmit={handleDelete}
          isSubmitDisabled={isLoadingDeleteUser}
        />
      )}
    </>
  );
};

export default UserList;
