import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BoxEmpty, Table } from '@nordictrustee/nt-ui-library';
import { MANAGERS } from 'router/url';
import { Group } from 'modules/Managers/Managers.types';
import { ManagersContext } from 'modules/Managers/ManagersContext';
import { firstColPadding } from 'utils/constants';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { ActionsGeneric } from 'utils/types/ActionsGeneric';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';

const columns: Table.Column<Group>[] = [
  {
    field: 'name',
    title: 'Name',
    width: '30%',
    render: (item) => (
      <Table.ColumnWithTooltip title={item.name}>
        {item.name}
      </Table.ColumnWithTooltip>
    ),
    cellStyle: { ...firstColPadding.cellStyle },
    headerStyle: { ...firstColPadding.headerStyle },
  },
  {
    field: 'remark',
    title: 'Remark',
    width: '70%',
    render: (item) => (
      <Table.ColumnWithTooltip title={item.remark}>
        {item.remark}
      </Table.ColumnWithTooltip>
    ),
  },
];

interface Props {
  actions: ActionsGeneric<Group>;
  groupsData: Group[];
  isLoadingGroups: boolean;
  getGroups: AxiosPromiseGeneric<Group[]>;
}

const GroupsTable = ({
  actions,
  groupsData,
  isLoadingGroups,
  getGroups,
}: Props) => {
  const { push } = useHistory();
  const {
    managerID,
    groupID,
    filterStringFromQuery,
    pageFromQuery,
    pageSizeFromQuery,
  } = useContext(ManagersContext);

  useEffect(() => {
    if (managerID > 0) {
      getGroups();
    }
  }, [getGroups, managerID]);

  return managerID ? (
    <Table.Root
      columns={columns}
      data={groupsData}
      isLoading={isLoadingGroups}
      disableDefaultSearchAndFilter
      options={{
        paging: false,
        search: false,
        toolbar: false,
        tableLayout: 'fixed',
        maxBodyHeight: '20vh',
        minBodyHeight: '20vh',
        rowStyle: (rowData: Group) => selectedRowStyle(rowData.id, groupID),
      }}
      actions={actions}
      onRowClick={(_, item) => {
        push(
          `${MANAGERS}?managerId=${managerID}&groupId=${item?.id}&page=${pageFromQuery}&pageSize=${pageSizeFromQuery}&query=${filterStringFromQuery}`,
        );
      }}
    />
  ) : (
    <BoxEmpty height="20vh" message="Select Manager for the details" />
  );
};

export default GroupsTable;
