import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Select } from '@nordictrustee/nt-ui-library';
import { ManagerAddFormFields } from 'modules/Managers/Managers.types';
import useDebounce from 'utils/hooks/useDebounce';
import { checkIfErrors } from 'utils/reactHookFormUtils';
import * as api from '../../../api';

type Props = {
  formContent?: ManagerAddFormFields;
};

export const ManagerFormManagerStep = ({ formContent }: Props) => {
  const methods = useFormContext();
  const { reset, control, errors } = methods;
  const [managerSearchText, setManagerSearchText] = useState('');

  const searchQuery = useDebounce(managerSearchText, 300);

  const { managersData, isLoadingManagers, getManagers } = api.useGetManagers(
    searchQuery,
  );

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);

  useEffect(() => {
    getManagers();
  }, [getManagers]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setManagerSearchText(event?.target?.value ?? '');
  };

  const assignableSelectOptions = useMemo(
    () =>
      managersData?.results.map((manager) => ({
        label: manager.name,
        value: manager.id,
      })),
    [managersData?.results],
  );

  return (
    <Select
      control={control}
      name="managerID"
      options={!isLoadingManagers ? assignableSelectOptions : []}
      label="Manager"
      data-testid="manager"
      onInput={handleInputChange}
      error={checkIfErrors('managerID', errors)}
      maxMenuHeight={170}
      helperText="Search for manager"
      isClearable
      isRequired
      autoFocus
    />
  );
};

export default ManagerFormManagerStep;
